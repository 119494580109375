import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get, post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { Container, Row, Col, Spinner } from 'reactstrap';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { app } from "../../firebase";
import { getAuth } from "firebase/auth";

const db = getFirestore(app);
const auth = getAuth(app);

const PhonePayStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState({
        message: '',
        transactionId: '',
        status: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { merchantTransactionId, uniquetag } = useParams();

    const updateFirebaseRecords = async (paymentData) => {
        try {
            try {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                if (!currentUser) {
                    throw new Error("No user found in localStorage");
                }
           
                const userId = currentUser.uid;
                if (!userId) {
                    throw new Error("No user ID found");
                }

                const userDocRef = doc(db, "users", userId);
                const userDoc = await getDoc(userDocRef);

                if (!userDoc.exists()) {
                    throw new Error("User document not found in Firestore");
                }

                const userData = userDoc.data();
                await updateDoc(userDocRef, {
                    ...userData,
                    disabled: false,
                    transaction_id: paymentData,
                });

                const purchaseRef = doc(db, "purchases", userId);
                await updateDoc(purchaseRef, {
                    transaction_id: paymentData,
                    purchase_at: new Date()
                });
                return true;
            } catch (err) {
                console.error("Error updating user records:", err.message);
                throw err;
            }
        } catch (error) {
            console.error("Firebase update error:", error);
            return false;
        }
    };

    const updateTransactionDetails = async (paymentData, uniquetag) => {
        try {
            const updateResponse = await post(
                url.UPDATE_TRANSACTION_DETAILS + `/${uniquetag}`,
                {
                    transaction_id: paymentData,
                }
            );
            console.log("Transaction details update response:", updateResponse);
            
            // if (!updateResponse || updateResponse.code !== "PAYMENT_SUCCESS") {
            //     throw new Error("Failed to update transaction details");
            // }
            return updateResponse;
        } catch (error) {
            console.error("Transaction update error:", error);
            // throw error;
        }
    };

    const fetchPaymentStatus = async (merchantTransactionId) => {
        const response = await get(`${url.PAYMENT_STATUS}/${merchantTransactionId}`);
        console.log("Payment status response:", response);
        return response;
    };

    useEffect(() => {
        const processPayment = async () => {
            try {
                console.log("merchantTransactionId", merchantTransactionId);
                if (!merchantTransactionId) {
                    throw new Error('Transaction ID not found');
                }
                const response = await fetchPaymentStatus(merchantTransactionId);
                
                if (response.data.success) {
                    console.log("this is working");
                    setPaymentStatus({
                        status: response.data.code ,
                        transactionId: response.data.data.transactionId ,
                        message: response.data.message 
                    });
                    
                    console.log("payment data", response.data);
                    
                    if (response.data.data?.transactionId) {
                        await Promise.all([
                            updateTransactionDetails(response.data.data.transactionId, uniquetag),
                            updateFirebaseRecords(response.data.data.transactionId)
                        ]);
                    }
                    
                    console.log("All updates completed successfully");

                } else {
                    console.log("Payment unsuccessful:", response.data);
                    setPaymentStatus({
                        status: response.data.code ,
                        transactionId: response.data.data.transactionId,
                        message: response.data.message 
                    });
                }
            } catch (err) {
                console.error(err.message || 'Failed to process payment');
                setPaymentStatus({
                    status: 'PAYMENT_ERROR',
                    transactionId: '',
                    message: err.message || 'Failed to process payment'
                });
                // setError(err.message || 'Failed to process payment');
            } finally {
                setLoading(false);
            }
        };

        processPayment();
    }, [merchantTransactionId, uniquetag]);

    const getStatusIcon = (status) => {
        switch (status) {
            case 'PAYMENT_SUCCESS':
                return <i className="fas fa-check-circle text-success fa-5x" />;
            case 'PAYMENT_ERROR':
                return <i className="fas fa-times-circle text-danger fa-5x" />;
            case 'PAYMENT_PENDING':
                return <i className="fas fa-clock text-warning fa-5x" />;
            default:
                return <i className="fas fa-question-circle text-secondary fa-5x" />;
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'PAYMENT_SUCCESS':
                return 'text-success';
            case 'PAYMENT_ERROR':
                return 'text-danger';
            case 'PAYMENT_PENDING':
                return 'text-warning';
            default:
                return 'text-secondary';
        }
    };

    if (loading) {
        return (
            <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
                <div className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <h4 className="mt-3">Processing Payment</h4>
                    <p className="text-muted">Please wait while we verify your transaction...</p>
                </div>
            </Container>
        );
    }

    // if (error) {
    //     return (
    //         <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
    //             <div className="text-center">
    //                 <i className="fas fa-exclamation-circle text-danger fa-5x mb-3" />
    //                 <h4 className="text-danger">Error</h4>
    //                 <p className="text-muted">{error}</p>
    //             </div>
    //         </Container>
    //     );
    // }

    return (
        <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
            <Row className="justify-content-center w-100">
                <Col xs={12} sm={10} md={8} lg={6} xl={4}>
                    <div className="card border-0 shadow-lg rounded-lg">
                        <div className="card-body p-5">
                            <div className="text-center">
                                <div className="mb-4">
                                    {getStatusIcon(paymentStatus.status)}
                                </div>
                                <h3 className={`${getStatusColor(paymentStatus.status)} font-weight-bold mb-4`}>
                                    {paymentStatus.status || 'Status Unknown'}
                                </h3>
                                <div className="bg-light p-3 rounded mb-3">
                                    <p className="mb-1 text-muted">Transaction ID</p>
                                    <h6 className="font-weight-bold">{paymentStatus.transactionId || 'N/A'}</h6>
                                </div>
                                <p className="text-muted">
                                    {paymentStatus.message || 'No message available'}
                                </p>
                                
                                {paymentStatus.status === 'PAYMENT_ERROR' && (
                                    <button className="btn btn-danger mt-3">
                                        <i className="fas fa-redo mr-2"></i>
                                        Try Again
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PhonePayStatus;
