//REGISTER
export function getCurrentUser() {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
        try {
            const parsedAuthUser = JSON.parse(authUser);
            if (parsedAuthUser && parsedAuthUser.user) {
                return parsedAuthUser.user;
            } else {
                console.warn("User data is missing in authUser");
                return null;
            }
        } catch (e) {
            console.error("Error parsing authUser from localStorage", e);
            return null;
        }
    }
    return {};
}

export function getCurrentUserId() {
    const currentUser = getCurrentUser();
    return currentUser ? currentUser.id : null;
}
export function isAdmin() {
    const currentUser = getCurrentUser();
    return currentUser && (currentUser.role === 'admin' || currentUser.role === 'superadmin');
}

export const CHECK_PENDING = `/orders/checkpending`
export const REQUEST_PAYMENT = `/users/request-payment`
export const GET_PAYMENT_REQUEST = `/orders/paylist`
export const PAY_TOGGLE = 'users/paid'

export const PAYMENT_STATUS = "/payment/status"
export const PAYMENT_INIT = "/payment/pay"
export const POST_FAKE_REGISTER = "/post-fake-register"
export const FORGET_PASSWORD = "/auth/forgot-password"
//ADHDAR verify
export const AADHAR_VERIFY = `/orders/aadhar-verify`
export const UPDATE_TRANSACTION_DETAILS = `/orders/update-transaction-details`

//suer
export const CHANGE_RENEWAL_STATUS = `/orders/change-renewal-status/:orderId`
export const GET_REQ_USR = `/tba/requests`
export const APPROVE_REQUEST = `/tba/approvereq`
export const CHECK_LEAD_REQUEST = `/tba/check-lead-request`
export const REQUEST_LEADS = `/tba/request-leads`

//
export const GET_ALL_COUNTS= `/tba/counts`
//Profile
export const GET_PROFILE = `/users/profile`
export const UPDATE_PROFILE=`/users/update`
export const UPLOAD_IMAGE = `/image/upload`
export const GET_COUNT_BY_ROLE  = `/users/count-by-role`
export const UPDATE_TBA_STATUS = `/tba/toggle-status`

export const GET_USER_DETAILS = `/users`
export const GET_LEAD_PROFILE = `/leads/getlead`
export const GET_ORDER_PAGE = `/orders/getorder`
//LOGIN
export const POST_FAKE_LOGIN = "/auth/login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"
export const CHANGE_PASSWORD = `/users/change-password`
export const RESET_PASSWORD = `/auth/reset-password`

//TBA
export const ADD_TBA = `/tba/add`
export const GET_TBAS = `/tba/list`
export const GET_COMMISSION = `/earning/commision`
//LEADS
export const ADD_LEAD = `/leads/add`
export const ADD_LEAD_CSV = `/leads/add-csv`

export const UPDATE_LEAD_INTEREST = `/leads/update-interest`
export const GET_LEAD_FOR_ASSIGN = `/leads/get-unassigned`
export const ASSIGN_TBA = `/leads/assign-tba`
export const TRANSFER_LEAD = `/leads/transfer`
export const DEALLOCATE_LEAD = `/leads/deallocate`
export const GET_LEAD = `/leads/get-leads`
export const GET_LEAD_BY_SEARCH = `/leads/get-search`
export const GET_LEAD_OF_TBA = `/leads/get-leads-of-tba`

//COURSES
export const ADD_COURSE = `/courses/add`
export const GET_COURSES = `/courses/list`
export const GET_SINGLE_COURSE = `/courses/single`
export const DELETE_COURSE = `/courses/toggle`
export const EDIT_COURSE = `/courses/edit`
//extra
export const GET_EARNINGS = `/earning/earn`
export const GET_RENEWAL_ORDERS = `/orders/expired`
//Orders
export const PAY_COMMISSION = `/orders/pay-commission`
export const GET_ORDERS = `/orders/list`
export const GET_ORDERS_BY_STATUS = `/orders/list`
export const ADD_ORDER = `/orders/add`
export const CANCEL_ORDER = `/orders/cancel`
// export const GET_ORDER_DETAILS = `/orders/get`
export const GET_ORDER_DETAILS_BY_UNIQUE_TAG = `/orders/get-by-unique-tag`
export const RENEW_ORDER=`/orders/renew`
export const ORDER_CONFIRMATION = `/orders/confirm`

//EARNING
export const GET_EARNING = `/earning/dashboard`

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";
export const DELETE_MESSAGE = "/delete/message";

//TASKS
export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"
