import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import { Alert, Button, Card, CardBody, Row, Col, Table, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Form, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from 'framer-motion';

const OrderRenewalPage = (props) => {
    document.title = "Order Renewal";

    const breadcrumbItems = [
        { title: "Orders", link: "/orders" },
        { title: "Renewal", link: "#" }
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Order Renewal', breadcrumbItems);
    }, [props]);

    const { id } = useParams();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [modal, setModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const [aadharNumber, setAadharNumber] = useState('');
    const [isAadharVerified, setIsAadharVerified] = useState(false);

    useEffect(() => {
        fetchOrderDetails();
    }, [id, isAadharVerified]);

    const fetchOrderDetails = async () => {
        try {
            setIsLoading(true);
            const response = await get(`${url.GET_ORDER_PAGE}/${id}`);
            setOrderDetails(response);
            if (response.courses) {
                const initialCart = response.courses.map(course => ({ ...course, selected: true }));
                setCart(initialCart);
                calculateTotal(initialCart);
            }
        } catch (error) {
            setError('Failed to fetch order details. Please try again.');
            console.error('Error fetching order details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleCourseSelection = (courseId) => {
        setCart(prevCart => {
            const updatedCart = prevCart.map(course =>
                course.id === courseId ? { ...course, selected: !course.selected } : course
            );
            calculateTotal(updatedCart);
            return updatedCart;
        });
    };

    const calculateTotal = (updatedCart) => {
        const newTotal = updatedCart.reduce((total, course) => course.selected ? total + course.price : total, 0);
        setTotal(newTotal);
    };

    const handlePlaceOrder = async () => {
        setIsLoading(true);
        try {
            const selectedCourses = cart.filter(course => course.selected);
            if (selectedCourses.length === 0) {
                setError('Please select at least one course to renew.');
                setIsLoading(false);
                return;
            }
            const orderData = {
                orderType: "renewal",
                leadId: orderDetails.LeadId,
                prevOderId: orderDetails.id,
                leadName: orderDetails.leadName,
                email: orderDetails.email,
                phoneNumber: orderDetails.phoneNumber,
                total: total,
                commission: selectedCourses.reduce((sum, course) => sum + (course.renewalCommissionValue || 0), 0),
                courseDetails: selectedCourses.map(item => ({
                    courseId: parseInt(item.id),
                    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + parseInt(item.duration))).toISOString()
                })),
            };
            const response = await post(url.ADD_ORDER, orderData);
            setSuccess('Order renewed successfully');
            setOrderDetails({
                uniqueId: response.id,
                verificationCode: response.uniquetag,
                leadName: response.leadName,
            });
            await post(url.CHANGE_RENEWAL_STATUS.replace(':orderId', orderDetails.id));
            setModal(true);
        } catch (error) {
            setError('Failed to renew order. Please try again.');
            console.error('Error renewing order:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggle = () => setModal(!modal);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleClose = () => {
        toggle();
        navigate('/pending-order');
    };

    const handleAadharVerification = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');
        try {
            const response = await post(url.AADHAR_VERIFY, { orderid: orderDetails.id, aadharNumber });
            if (response.verified) {
                setIsAadharVerified(true);
                setSuccess('Aadhar verified successfully');
            } else {
                setError('Aadhar verification failed. Please check the number and try again.');
            }
        } catch (error) {
            setError('Aadhar verification failed. Please try again.');
            console.error('Error verifying Aadhar:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAadharChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        if (value.length <= 12) {
            setAadharNumber(value);
        }
    };

    if (isLoading) {
        return (
            <div className="page-content d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="page-content"
        >
            <Container fluid>
                <Card>
                    <CardBody>
                        <h2 className="mb-4">Renew Order</h2>
                        {error && <Alert color="danger">{error}</Alert>}
                        {success && <Alert color="success">{success}</Alert>}

                        {!isAadharVerified ? (
                            <motion.div
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <h4 className="mb-3">Aadhar Verification</h4>
                                <Form onSubmit={handleAadharVerification}>
                                    <FormGroup>
                                        <Label for="aadharNumber">Aadhar Number</Label>
                                        <Input
                                            type="text"
                                            name="aadharNumber"
                                            id="aadharNumber"
                                            placeholder="Enter your 12-digit Aadhar number"
                                            value={aadharNumber}
                                            onChange={handleAadharChange}
                                            maxLength={12}
                                            required
                                        />
                                    </FormGroup>
                                    <Button color="primary" type="submit" disabled={isLoading || aadharNumber.length !== 12}>
                                        {isLoading ? 'Verifying...' : 'Verify Aadhar'}
                                    </Button>
                                </Form>
                            </motion.div>
                        ) : (
                            <Row className="mb-4">
                                <Col lg={6}>
                                    <motion.div
                                        initial={{ x: -100, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <h4>Customer Details</h4>
                                        <div className="table-responsive">
                                            <Table bordered hover className="mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{orderDetails.leadName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>{orderDetails.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone</th>
                                                        <td>{orderDetails.phoneNumber}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </motion.div>
                                </Col>
                                <Col lg={6}>
                                    <motion.div
                                        initial={{ x: 100, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <h4>Courses to Renew</h4>
                                        <div className="table-responsive">
                                            <Table bordered hover className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>Course Name</th>
                                                        <th>Duration</th>
                                                        <th>Price</th>
                                                        <th>Commission</th>
                                                        <th>Renewal Commission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cart.map((course, index) => (
                                                        <motion.tr
                                                            key={index}
                                                            initial={{ opacity: 0, y: 20 }}
                                                            animate={{ opacity: 1, y: 0 }}
                                                            transition={{ delay: index * 0.1 }}
                                                            className={course.selected ? "table-active" : ""}
                                                        >
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={course.selected}
                                                                    onChange={() => toggleCourseSelection(course.id)}
                                                                />
                                                            </td>
                                                            <td>{course.coursename}</td>
                                                            <td>{course.duration}</td>
                                                            <td>₹{course.price}</td>
                                                            <td>₹{course.commissionValue}</td>
                                                            <td>₹{course.renewalCommissionValue}</td>
                                                        </motion.tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="text-end mt-3">
                                            <h5>Total: ₹{total}</h5>
                                            <Button
                                                color="success"
                                                onClick={handlePlaceOrder}
                                                disabled={!cart.some(course => course.selected) || isLoading}
                                            >
                                                {isLoading ? 'Processing...' : 'Place Order'}
                                            </Button>
                                        </div>
                                    </motion.div>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </Container>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Order Confirmation</ModalHeader>
                <ModalBody>
                    <p>Hello {orderDetails.leadName}👋,</p>
                    <p>Thank you for purchasing a course from True Trader. Your Order Number is {orderDetails.uniqueId}. We hope you find the content valuable and gain new insights.</p>
                    <p>Please find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.</p>
                    <p>Unique Verification code: {orderDetails.verificationCode}</p>
                    <p>Verification link: <a href="https://mytruetrader.com/verify" target="_blank" rel="noopener noreferrer">mytruetrader.com/verify</a></p>
                </ModalBody>
                <ModalFooter>
                    <CopyToClipboard
                        text={`Hello ${orderDetails.leadName}👋,\n\nThank you for purchasing a course from True Trader. Your Order Number is ${orderDetails?.uniqueId}. We hope you find the content valuable and gain new insights.\n\nPlease find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.\n\nUnique Verification code: ${orderDetails?.verificationCode}\nVerification link: https://mytruetrader.com/verify`}
                        onCopy={handleCopy}
                    >
                        <Button color="primary">
                            {copied ? 'Copied!' : 'Copy to Clipboard'}
                        </Button>
                    </CopyToClipboard>
                    <Button color="secondary" onClick={handleClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </motion.div>
    );
};

export default connect(null, { setBreadcrumbItems })(OrderRenewalPage);