import React from 'react';
import { Table, Card, Badge, Button } from "reactstrap";

const LatestOrders = ({ orders }) => {
    return (
        <React.Fragment>
            <Card >
                <div className="card-body">
                    <h4 className="card-title mb-4">Latest Orders</h4>

                    <div className="table-responsive">
                        <Table className="align-middle table-centered table-vertical table-nowrap mb-1">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Lead Name</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    <th>Order Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, key) => (
                                    <tr key={key}>
                                        <td>#{order.id}</td>
                                        <td>
                                            {order.leadName}
                                        </td>
                                        <td><Badge className={`rounded-pill bg-${order.status === 'pending' ? 'warning' : order.status === 'completed' ? 'success' : 'danger'}`}>{order.status}</Badge></td>
                                        <td>
                                        ₹ {order.total}
                                        </td>
                                        <td>
                                            {new Date(order.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '/')}
                                        </td>
                                        {/* <td>
                                            <Button color="secondary" size="sm" className="waves-effect waves-light">Edit</Button>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}

export default LatestOrders;