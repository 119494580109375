import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Input, Form, FormGroup, Label, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { post, get } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from 'date-fns';
import { error } from 'toastr';
import Paginate from 'pages/Ui/Page';

const Commission = (props) => {
    document.title = "Commission";

    const [commissionData, setCommissionData] = useState({});
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filterStatus, setFilterStatus] = useState('unpaid');
    const [modal, setModal] = useState(false);
    const [paymentNote, setPaymentNote] = useState('');
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showUserList, setShowUserList] = useState(false);
    const [userListData, setUserListData] = useState([]);

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Commission", link: "#" },
        ];
        props.setBreadcrumbItems('Commission', breadcrumbItems);
        fetchCommissionData(1);
    }, []);

    useEffect(() => {
        fetchCommissionData(1);
    }, [filterStatus]);

    const fetchCommissionData = async (page = currentPage) => {
        setLoading(true);
        try {
            const response = await post(`${url.GET_COMMISSION}?startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}&page=${page}&limit=${itemsPerPage}&commissionStatus=${filterStatus}`);
            setCommissionData(response.orders);
            setTotalPages(response.totalPages || 1);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error fetching commission data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserList = async () => {
        try {
            const response = await get(url.GET_PAYMENT_REQUEST);
            console.log(response)
            if(response.success){
                setUserListData(response.users);
                setShowUserList(true);
            }else{
                window.alert("error fetching details please wait for a while");
            }
           
        } catch (error) {
            console.error('Error fetching user list:', error);
            window.alert("error fetching details please wait for a while");

        }
    };

    const handlePaymentDone = async (userId) => {
        try {
            await post(`${url.PAY_TOGGLE}/${userId}`);
            // Refresh user list after payment
            fetchUserList();
        } catch (error) {
            console.error('Error marking payment done:', error);
        }
    };


    const handlePayNow = (userId, orderIds) => {
        setSelectedPayment({ userId, orderIds });
        setModal(true);
    };

    const confirmPayment = async () => {
        try {
            const response = await post(url.PAY_COMMISSION, { 
                userId: selectedPayment.userId, 
                orderIds: selectedPayment.orderIds,
                notes: paymentNote
            });
            console.log('Pay now response:', response);
            setModal(false);
            setPaymentNote('');
            fetchCommissionData();
        } catch (error) {
            console.error('Error paying commission:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchCommissionData(1);
    };

    const handlePageChange = (pageNumber) => {
        fetchCommissionData(pageNumber);
    };

    const csvData = Object.entries(commissionData).flatMap(([userId, orders]) => 
        orders.map(order => ({
            UserID: userId,
            Username: order.creator.username,
            OrderID: order.id,
            Commission: `₹${order.commission.toFixed(2)}`,
            CreatedAt: format(new Date(order.createdAt), 'dd/MM/yyyy')
        }))
    );

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.5
    };

    const pageVariants = {
        initial: { opacity: 0, x: "-100%" },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: "100%" }
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            <Row className="mb-3">
                <Col md={12}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="startDate">Start Date</Label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="endDate">End Date</Label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="filterStatus">Filter Status</Label>
                                    <Input
                                        type="select"
                                        name="filterStatus"
                                        id="filterStatus"
                                        value={filterStatus}
                                        onChange={(e) => setFilterStatus(e.target.value)}
                                    >
                                        <option value="unpaid">Unpaid</option>
                                        <option value="paid">Paid</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={3} className="d-flex align-items-end">
                                <FormGroup className="mb-0 d-flex">
                                    <Button color="primary" type="submit" className="me-2">Search</Button>
                                    <CSVLink
                                        data={csvData}
                                        filename={"commission_data.csv"}
                                        className="btn btn-primary me-2"
                                        target="_blank"
                                    >
                                        Download CSV
                                    </CSVLink>
                                    <Button color="info" onClick={fetchUserList}>
                                        Payment Request
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            {showUserList && (
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4">User Payment List</CardTitle>
                                <Table className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>ID</Th>
                                            <Th>Username</Th>
                                            <Th>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {userListData.map((user) => (
                                            <Tr key={user.id}>
                                                <Td>{user.id}</Td>
                                                <Td>{user.username}</Td>
                                                <Td>{user.name}</Td>
                                                <Td>{user.email}</Td>
                                                <Td>
                                                    <Button 
                                                        color="success" 
                                                        size="sm"
                                                        onClick={() => handlePaymentDone(user.id)}
                                                    >
                                                        Payment Done
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            <AnimatePresence mode="wait">
                {loading ? (
                    <motion.div
                        key="loading"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="text-center"
                    >
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </motion.div>
                ) : (
                    <motion.div
                        key="content"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">TBA Commission Summary</CardTitle>
                                        <Table className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr>
                                                    <Th>User ID</Th>
                                                    <Th>Username</Th>
                                                    <Th>Order IDs</Th>
                                                    <Th>Total Commission</Th>
                                                    <Th>{filterStatus === 'paid' ? 'Status' : 'Action'}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(commissionData).map(([userId, orders]) => {
                                                    const totalCommission = orders.reduce((sum, order) => sum + order.commission, 0);
                                                    const orderIds = orders.map(order => order.id);
                                                    return (
                                                        <Tr key={userId}>
                                                            <Td>{userId}</Td>
                                                            <Td>{orders[0]?.creator.username}</Td>
                                                            <Td>
                                                                <div className="d-flex flex-wrap">
                                                                    {orders.map((order) => (
                                                                        <motion.div
                                                                            key={order.id}
                                                                            whileHover={{ scale: 1.05 }}
                                                                            whileTap={{ scale: 0.95 }}
                                                                            className="m-1"
                                                                        >
                                                                            <Link 
                                                                                to={`/order/${order.id}`}
                                                                                className="btn btn-sm btn-outline-primary"
                                                                            >
                                                                                {order.id}
                                                                            </Link>
                                                                        </motion.div>
                                                                    ))}
                                                                </div>
                                                            </Td>
                                                            <Td>₹{totalCommission.toFixed(2)}</Td>
                                                            <Td>
                                                                {filterStatus === 'paid' ? (
                                                                    <span className="badge bg-success">Paid</span>
                                                                ) : (
                                                                    <motion.div
                                                                        whileHover={{ scale: 1.05 }}
                                                                        whileTap={{ scale: 0.95 }}
                                                                    >
                                                                        <Button
                                                                            color="info"
                                                                            size="sm"
                                                                            onClick={() => handlePayNow(userId, orderIds)}
                                                                        >
                                                                            Pay Now
                                                                        </Button>
                                                                    </motion.div>
                                                                )}
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                        <div className="d-flex justify-content-center">
                                <Paginate
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </motion.div>
                )}
            </AnimatePresence>

            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)}>Confirm Payment</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="paymentNote">Payment Note</Label>
                        <Input
                            type="textarea"
                            name="paymentNote"
                            id="paymentNote"
                            value={paymentNote}
                            onChange={(e) => setPaymentNote(e.target.value)}
                            placeholder="Enter payment note..."
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmPayment}>Pay</Button>{' '}
                    <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </motion.div>
    );
};

export default connect(null, { setBreadcrumbItems })(Commission);
