import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Alert } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { get, post } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { Link } from 'react-router-dom'
import { CSVLink } from "react-csv"
import { isAdmin } from "../../helpers/url_helper"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import Paginate from 'pages/Ui/Page'

const OrderPending = (props) => {
    document.title = "Pending Orders";

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [infoModal, setInfoModal] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState(null);
    const [sortOrder, setSortOrder] = useState('newest'); // Added sort order state

    // Search states
    const [searchName, setSearchName] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Orders", link: "#" },
            { title: "Pending Orders", link: "#" },
        ];
        props.setBreadcrumbItems('Orders', breadcrumbItems);
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const status = "pending";
            const searchParams = new URLSearchParams();
            searchParams.append('page', currentPage);
            
            if (startDate && endDate) {
                searchParams.append('startDate', format(startDate, 'yyyy-MM-dd'));
                searchParams.append('endDate', format(endDate, 'yyyy-MM-dd'));
            }
            if (searchName) searchParams.append('name', searchName);
            if (searchPhone) searchParams.append('phone', searchPhone);
            if (searchEmail) searchParams.append('email', searchEmail);

            const response = await get(`${url.GET_ORDERS_BY_STATUS}/${status}?${searchParams.toString()}`);
            let sortedOrders = [...response.orders];
            
            // Sort orders based on createdAt date
            sortedOrders.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
            });
            
            setOrders(sortedOrders);
            setTotalPages(response.totalPages);
            setTotalItems(response.totalItems);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch orders. Please try again.');
            setOrders([]);
        } finally {
            setLoading(false);
        }
    }

    const handleSortChange = (e) => {
        const newSortOrder = e.target.value;
        setSortOrder(newSortOrder);
        const sortedOrders = [...orders].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return newSortOrder === 'newest' ? dateB - dateA : dateA - dateB;
        });
        setOrders(sortedOrders);
    };

    const clearFilters = () => {
        setSearchName('');
        setSearchPhone('');
        setSearchEmail('');
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(1);
        fetchData();
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData();
    };

    const toggleModal = () => setModal(!modal);
    const toggleInfoModal = () => setInfoModal(!infoModal);

    const handleCancelOrder = async (orderId) => {
        setSelectedOrderId(orderId);
        toggleModal();
    }

    const confirmCancelOrder = async () => {
        try {
            await post(`${url.CANCEL_ORDER}/${selectedOrderId}`);
            fetchData();
            toggleModal();
        } catch (error) {
            console.error('Error cancelling order:', error);
        }
    }

    const handleDateFilter = (e) => {
        e.preventDefault();
        if (startDate > endDate) {
            alert("Start date must be before end date");
            return;
        }
        setCurrentPage(1);
        fetchData();
    }

    const handleInfoClick = (order) => {
        setSelectedOrderDetails(order);
        toggleInfoModal();
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(`Hello ${selectedOrderDetails?.leadName}👋,\n\nThank you for purchasing a course from True Trader. Your Order Number is ${selectedOrderDetails?.id}. We hope you find the content valuable and gain new insights.\n\nPlease find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.\n\nUnique Verification code: ${selectedOrderDetails?.uniquetag}\nVerification link: https://mytruetrader.com/verify`)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Failed to copy: ', err));
    }

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'dd-MM-yyyy');
    }

    const csvData = orders.map(item => ({
        TBA: item.Createdby,
        OrderID: item.id,
        LeadName: item.leadName,
        Email: item.email,
        Phone: item.phoneNumber,
        Total: `${item.total} ₹`,
        Commission: `${item.commission} ₹`,
        Status: item.status,
        OrderType: item.orderType || 'N/A',
        CreatedDate: formatDate(item.createdAt),
        UniqueTag: item.uniquetag || 'N/A'
    }));

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <React.Fragment>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4">Pending Orders</CardTitle>
                                
                                <div className="mb-4 p-3 bg-light rounded">
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input 
                                                    type="text" 
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    placeholder="Search by name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Phone Number</Label>
                                                <Input 
                                                    type="text"
                                                    value={searchPhone}
                                                    onChange={(e) => setSearchPhone(e.target.value)}
                                                    placeholder="Search by phone"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <Input 
                                                    type="email"
                                                    value={searchEmail}
                                                    onChange={(e) => setSearchEmail(e.target.value)}
                                                    placeholder="Search by email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} className="d-flex align-items-end mb-3">
                                            <Button 
                                                color="primary" 
                                                onClick={handleSearch}
                                                className="me-2"
                                            >
                                                Search
                                            </Button>
                                            <Button 
                                                color="secondary" 
                                                onClick={clearFilters}
                                            >
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <Form onSubmit={handleDateFilter} className="mb-3">
                                    <Row className="align-items-center">
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="startDate">Start Date</Label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="endDate">End Date</Label>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <Button color="primary" type="submit" size="lg" className="w-100">Filter</Button>
                                        </Col>
                                       
                                        {isAdmin() && (
                                            <Col md={2} className="d-flex align-items-end">
                                                <CSVLink data={csvData} filename={"pending_orders.csv"} className="btn btn-primary btn-lg w-100">
                                                    Download CSV
                                                </CSVLink>
                                            </Col>
                                        )}
                                        <Col md={2} className="d-flex align-items-end mt-3">
                                            <FormGroup size="lg" className="w-100">
                                                <Input
                                                    type="select"
                                                    value={sortOrder}
                                                    onChange={handleSortChange}
                                                    className="form-control-lg btn btn-primary"
                                                >
                                                    <option value="newest">Newest First</option>
                                                    <option value="oldest">Oldest First</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <AnimatePresence>
                                    {loading ? (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="text-center"
                                        >
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="table-rep-plugin"
                                        >
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>TBA</Th>
                                                            <Th>Order ID</Th>
                                                            <Th>Lead Name</Th>
                                                            <Th>Email</Th>
                                                            <Th>Phone</Th>
                                                            <Th>Total</Th>
                                                            <Th className="text-center">Commission</Th>
                                                            <Th>Status</Th>
                                                            <Th>Order Type</Th>
                                                            <Th>Created Date</Th>
                                                            <Th>Actions</Th>
                                                            <Th>Info</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {orders.length > 0 ? (
                                                            orders.map((item, index) => (
                                                                <Tr key={index}>
                                                                    <Td>{item.createdByUsername}</Td>
                                                                    <Td>
                                                                        <Link to={`/order/${item.id}`}>
                                                                            {item.id}
                                                                            {" "}
                                                                            <i className="fas fa-link mr-1"></i>
                                                                        </Link>
                                                                    </Td>
                                                                    <Td>{item.leadName}</Td>
                                                                    <Td>{item.email}</Td>
                                                                    <Td>{item.phoneNumber}</Td>
                                                                    <Td>{item.total} ₹</Td>
                                                                    <Td className="text-center">{item.commission} ₹</Td>
                                                                    <Td>{item.status}</Td>
                                                                    <Td>{item.OrderType}</Td>
                                                                    <Td>{formatDate(item.createdAt)}</Td>
                                                                    <Td>
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => handleCancelOrder(item.id)}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </Td>
                                                                    <Td>
                                                                        <Button
                                                                            color="info"
                                                                            size="sm"
                                                                            onClick={() => handleInfoClick(item)}
                                                                        >
                                                                            <i className="fas fa-info-circle"></i>
                                                                        </Button>
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        ) : (
                                                            <Tr>
                                                                <Td colSpan="13">No pending orders available</Td>
                                                            </Tr>   
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                <div className="d-flex justify-content-center">
                                <Paginate
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </motion.div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Confirm Cancellation</ModalHeader>
                <ModalBody>
                    Are you sure you want to cancel this order?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmCancelOrder}>Yes, Cancel</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>No, Keep It</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={infoModal} toggle={toggleInfoModal} size="lg">
                <ModalHeader toggle={toggleInfoModal}>Order Details</ModalHeader>
                <ModalBody>
                    {selectedOrderDetails && (
                        <>
                            <p>Hello {selectedOrderDetails.leadName}👋,</p>
                            <p>Thank you for purchasing a course from True Trader. Your Order Number is {selectedOrderDetails.id}. We hope you find the content valuable and gain new insights.</p>
                            <p>Please find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.</p>
                            <p>Unique Verification code: {selectedOrderDetails.uniquetag}</p>
                            <p>Verification link: <a href="https://mytruetrader.com/verify" target="_blank" rel="noopener noreferrer">mytruetrader.com/verify</a></p>
                            </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <Button color="primary" onClick={handleCopy} style={{height: '40px', margin: '0 10px'}}>
                            {copied ? 'Copied!' : 'Copy to Clipboard'}
                        </Button>
                        <Button color="secondary" onClick={toggleInfoModal} style={{height: '40px', margin: '0 10px'}}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(OrderPending);
