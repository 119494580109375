import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Pagination, PaginationItem, Button, PaginationLink, Form, FormGroup, Label, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { get, post } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import { Link } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from 'react-redux';

const Earning = (props) => {
    document.title = "Earnings";

    const [earnings, setEarnings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-GB').split('/').reverse().join('-'));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-GB').split('/').reverse().join('-'));
    const [totalCommission, setTotalCommission] = useState(0);
    const [showPaymentRequest, setShowPaymentRequest] = useState(false);

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Earnings", link: "#" },
        ];
        props.setBreadcrumbItems('Earnings', breadcrumbItems);
        fetchEarnings();
        fetchData();
    }, []);

    const fetchEarnings = async (page = activePage, start = startDate, end = endDate) => {
        try {
            setLoading(true);
            const formattedStartDate = start.split('-').reverse().join('/');
            const formattedEndDate = end.split('-').reverse().join('/');
            const response = await get(url.GET_EARNINGS, { 
                page: page, 
                limit: itemsCountPerPage, 
                startDate: formattedStartDate, 
                endDate: formattedEndDate 
            });
            setEarnings(response.orders);
            setTotalItems(response.totalItems);
            
            // Calculate total commission
            const total = response.orders.reduce((sum, order) => sum + (order.commisonPaid ? (order.commission || 0) : 0), 0);
            setTotalCommission(total);
        } catch (error) {
            console.error('Error fetching earnings:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const response = await get(url.CHECK_PENDING);
            if (response.success) {
                if (response.hasReachedThreshold) {
                    setShowPaymentRequest(true);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        fetchEarnings(pageNumber);
    };

    const handleDateFilter = (e) => {
        e.preventDefault();
        if (new Date(startDate) > new Date(endDate)) {
            alert("Start date must be before end date");
            return;
        }
        setActivePage(1);
        fetchEarnings(1, startDate, endDate);
    };

    const handlePaymentRequest = async () => {
        try {
            const response = await post(url.REQUEST_PAYMENT);
            alert("Payment request submitted successfully!");
            setShowPaymentRequest(false);
        } catch (error) {
            console.error('Error requesting payment:', error);
            alert("Failed to submit payment request");
        }
    };

    const totalPages = Math.ceil(totalItems / itemsCountPerPage);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">Your Earnings</CardTitle>
                            <div className="mb-4 p-4 bg-light rounded shadow-sm">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h5 className="text-muted mb-1">Total Paid Commission</h5>
                                        <h3 className="mb-0 text-primary">₹{totalCommission.toFixed(2)}</h3>
                                        <small className="text-muted">For Selected Period</small>
                                    </div>
                                    <div className="ms-3">
                                        {showPaymentRequest && (
                                            <Button color="success" onClick={handlePaymentRequest}>
                                                Request Payment
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Form onSubmit={handleDateFilter}>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="startDate">Start Date</Label>
                                            <Input
                                                type="date"
                                                name="startDate"
                                                id="startDate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="endDate">End Date</Label>
                                            <Input
                                                type="date"
                                                name="endDate"
                                                id="endDate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Button color="primary" type="submit" style={{marginTop: '32px'}}>
                                            Filter
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <Table id="tech-companies-1" className="table table-striped table-bordered">
                                        <Thead>
                                            <Tr>
                                                <Th>Order ID</Th>
                                                <Th>Commission</Th>
                                                <Th>Commission Paid</Th>
                                                <Th>Order Date</Th>
                                                <Th>Order Status</Th>
                                                <Th>Notes</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {earnings.length > 0 ? (
                                                earnings.map((earning, index) => (
                                                    <Tr key={index}>
                                                        <Td><Link to={`/order/${earning.id}`}>{earning.id}</Link></Td>
                                                        <Td>₹{earning.commission}</Td>
                                                        <Td>{earning.commisonPaid ? 'Yes' : 'No'}</Td>
                                                        <Td>{new Date(earning.createdAt).toLocaleDateString('en-GB')}</Td>
                                                        <Td>{earning.status}</Td>
                                                        <Td>{earning.readOnlyNote || 'No notes available'}</Td>
                                                    </Tr>
                                                ))
                                            ) : (
                                                <Tr>
                                                    <Td colSpan="6">No earnings found</Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <Pagination>
                                    <PaginationItem disabled={activePage === 1}>
                                        <PaginationLink previous onClick={() => handlePageChange(activePage - 1)} />
                                    </PaginationItem>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <PaginationItem active={i + 1 === activePage} key={i}>
                                            <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={activePage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageChange(activePage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(Earning);
