import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginate = ({ totalPages, currentPage, handlePageChange }) => {
    const maxPages = 5; 

    const getPaginationRange = () => {
        let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
        let endPage = Math.min(totalPages, startPage + maxPages - 1);

        if (endPage - startPage + 1 < maxPages) {
            startPage = Math.max(1, endPage - maxPages + 1);
        }

        return { startPage, endPage };
    };

    const { startPage, endPage } = getPaginationRange();

    return (
        <Pagination>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
            </PaginationItem>

            {[...Array(endPage - startPage + 1)].map((_, i) => {
                const pageNumber = startPage + i;
                return (
                    <PaginationItem key={pageNumber} active={pageNumber === currentPage}>
                        <PaginationLink onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </PaginationLink>
                    </PaginationItem>
                );
            })}

            <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
            </PaginationItem>
        </Pagination>
    );
};

export default Paginate;
