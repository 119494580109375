import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { get } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import { Link } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import { CSVLink } from "react-csv";
import { connect } from 'react-redux';
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../helpers/url_helper";
import Paginate from 'pages/Ui/Page';

const Renewals = (props) => {
    document.title = "Renewals";

    const isadmin = isAdmin();
    const [renewalOrders, setRenewalOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [modal, setModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [sortOrder, setSortOrder] = useState('newest');

    // Search states
    const [searchName, setSearchName] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Renewals", link: "#" },
        ];
        props.setBreadcrumbItems('Renewals', breadcrumbItems);

        fetchRenewalOrders(startDate, endDate);
    }, []);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    
    const fetchRenewalOrders = async (start = '', end = '') => {
        setLoading(true);
        try {
            let apiUrl = `${url.GET_RENEWAL_ORDERS}?page=${currentPage}&limit=${ordersPerPage}`;
            if (start && end) {
                apiUrl += `&startDate=${format(start, 'yyyy-MM-dd')}&endDate=${format(end, 'yyyy-MM-dd')}`;
            }
            if (searchName) apiUrl += `&name=${searchName.trim()}`;
            if (searchPhone) apiUrl += `&phone=${searchPhone.trim()}`;
            if (searchEmail) apiUrl += `&email=${searchEmail.trim()}`;

            const response = await get(apiUrl);
            let sortedOrders = [...(response.orders || [])];
            
            // Sort orders based on createdAt date
            sortedOrders.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
            });

            setRenewalOrders(sortedOrders);
            setTotalPages(response.totalPages || 1);
        } catch (error) {
            console.error('Error fetching renewal orders:', error);
            setRenewalOrders([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (e) => {
        const newSortOrder = e.target.value;
        setSortOrder(newSortOrder);
        const sortedOrders = [...renewalOrders].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return newSortOrder === 'newest' ? dateB - dateA : dateA - dateB;
        });
        setRenewalOrders(sortedOrders);
    };

    const clearFilters = () => {
        setSearchName('');
        setSearchPhone('');
        setSearchEmail('');
        setStartDate(new Date());
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 7)));
        setCurrentPage(1);
        fetchRenewalOrders();
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchRenewalOrders(startDate, endDate);
    };

    const handleDateFilter = (e) => {
        e.preventDefault();
        if (startDate > endDate) {
            alert("Start date must be before end date");
            return;
        }
        setCurrentPage(1);
        fetchRenewalOrders(startDate, endDate);
    };

    const csvData = renewalOrders.map(order => ({
        OrderID: order.id,
        LeadName: order.leadName,
        Email: order.email,
        Phone: order.phoneNumber,
        ExpiredCourses: order.courseDetails ? order.courseDetails.map(course => `${course.courseId} (${format(new Date(course.expiryDate), 'dd/MM/yyyy')})`).join(', ') : ''
    }));

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const toggleModal = () => setModal(!modal);

    const handleOrderSelection = (order) => {
        setSelectedOrder(order);
        toggleModal();
    };

    return (
        <React.Fragment>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4 mb-4">Expired Orders</CardTitle>

                                <div className="mb-4 p-3 bg-light rounded">
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input 
                                                    type="text" 
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    placeholder="Search by name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Phone Number</Label>
                                                <Input 
                                                    type="text"
                                                    value={searchPhone}
                                                    onChange={(e) => setSearchPhone(e.target.value)}
                                                    placeholder="Search by phone"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <Input 
                                                    type="email"
                                                    value={searchEmail}
                                                    onChange={(e) => setSearchEmail(e.target.value)}
                                                    placeholder="Search by email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} className="d-flex align-items-end mb-3">
                                            <Button 
                                                color="primary" 
                                                onClick={handleSearch}
                                                className="me-2"
                                            >
                                                Search
                                            </Button>
                                            <Button 
                                                color="secondary" 
                                                onClick={clearFilters}
                                            >
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <Form onSubmit={handleDateFilter} className="mb-4">
                                    <Row className="align-items-center">
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="startDate">Start Date</Label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                    popperClassName="datepicker-zindex-top"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="endDate">End Date</Label>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                    popperClassName="datepicker-zindex-top"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <Button color="primary" type="submit" size="lg" className="w-100">Filter</Button>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            {isAdmin() && (
                                                <CSVLink data={csvData} filename={"expired_orders.csv"} className="btn btn-primary btn-lg w-100">
                                                    Download CSV
                                                </CSVLink>
                                            )}
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end mt-3">
                                            <FormGroup size="lg" className="w-100">
                                                <Input
                                                    type="select"
                                                    value={sortOrder}
                                                    onChange={handleSortChange}
                                                    className="form-control-lg btn btn-primary"
                                                >
                                                    <option value="newest">Newest First</option>
                                                    <option value="oldest">Oldest First</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <AnimatePresence>
                                    {loading ? (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="text-center"
                                        >
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="table-responsive"
                                        >
                                            <Table id="renewal-orders" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Order ID</Th>
                                                        <Th>Lead Name</Th>
                                                        <Th>Phone Number</Th>
                                                        <Th>Email</Th>
                                                        <Th>Expired Courses</Th>
                                                        <Th>Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {renewalOrders && renewalOrders.length > 0 ? (
                                                        renewalOrders.map((order) => (
                                                            <Tr key={order.id}>
                                                                <Td>
                                                                    <Link to={`/order/${order.id}`}>
                                                                        {order.id}
                                                                        {" "}
                                                                        <i className="fas fa-link mr-1"></i>
                                                                    </Link>
                                                                </Td>
                                                                <Td>{order.leadName}</Td>
                                                                <Td>{order.phoneNumber}</Td>
                                                                <Td>{order.email}</Td>
                                                                <Td> 
                                                                    {order.courseDetails && order.courseDetails.map((course, index) => (
                                                                        <span key={index}>
                                                                            <Link to={`/course/${course.courseId}`}>
                                                                                {course.courseId}
                                                                                {" "}
                                                                                <i className="fas fa-link mr-1"></i>
                                                                            </Link>
                                                                            {index < order.courseDetails.length - 1 && ', '}
                                                                        </span>
                                                                    ))}
                                                                </Td>
                                                                <Td>
                                                                    <Link to={`/order-renew/${order.id}`} className="btn btn-primary btn-sm">Renew</Link>
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                    ) : (
                                                        <Tr>
                                                            <Td colSpan="6">No expired orders</Td>
                                                        </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                <div className="d-flex justify-content-center">
                                <Paginate
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Renew Order</ModalHeader>
                <ModalBody>
                    <p>Order ID: {selectedOrder ? selectedOrder.id : 'N/A'}</p>
                    <p>Lead Name: {selectedOrder ? selectedOrder.leadName : 'N/A'}</p>
                    <p>Email: {selectedOrder ? selectedOrder.email : 'N/A'}</p>
                    <p>Phone Number: {selectedOrder ? selectedOrder.phoneNumber : 'N/A'}</p>
                    <p>Expired Courses:</p>
                    {selectedOrder && selectedOrder.courseDetails ? (
                        <ul>
                            {selectedOrder.courseDetails.map((course, index) => (
                                <li key={index}>{course.courseId} ({format(new Date(course.expiryDate), 'dd/MM/yyyy')})</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No expired courses</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(Renewals);
