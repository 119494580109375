import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Badge } from 'reactstrap';
import { get } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import { useParams } from 'react-router-dom';

const Order = () => {
    document.title = "Order Details"
    const [orderDetails, setOrderDetails] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await get(`${url.GET_ORDER_PAGE}/${id}`);
                setOrderDetails(response);
            } catch (error) {
                console.error("Error fetching order details:", error);
            }
        };

        fetchOrderDetails();
    }, [id]);

    if (!orderDetails) return <div>Loading...</div>;

    return (
        <div className="container mt-5">
            <Card>
                <CardBody>
                    <h2 className="mb-4">Order Details</h2>
                    <Table bordered hover responsive className="mt-3">
                        <thead className="bg-light">
                            <tr>
                                <th>Field</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Order ID</th>
                                <td>{orderDetails.id}</td>
                            </tr>
                            <tr>
                                <th>Unique Tag</th>
                                <td>{orderDetails.uniquetag}</td>
                            </tr>
                            <tr>
                                <th>Lead Name</th>
                                <td>{orderDetails.leadName}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{orderDetails.email}</td>
                            </tr>
                            <tr>
                                <th>Phone Number</th>
                                <td>{orderDetails.phoneNumber}</td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <td>₹ {orderDetails.total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th>Commission</th>
                                <td>₹ {orderDetails.commission.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th>Payment ID</th>
                                <td>{orderDetails.PaymentId || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Lead ID</th>
                                <td>{orderDetails.LeadId}</td>
                            </tr>
                            <tr>
                                <th>Created By</th>
                                <td>{orderDetails.creator.username} ({orderDetails.creator.email})</td>
                            </tr>
                            <tr>
                                <th>Created At</th>
                                <td>{new Date(orderDetails.createdAt).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <th>Updated At</th>
                                <td>{new Date(orderDetails.updatedAt).toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <div className="mt-4">
                        <h4>Order Status</h4>
                        <Badge color={orderDetails.status === 'pending' ? 'warning' : orderDetails.status === 'completed' ? 'success' : 'danger'} className="p-2" style={{ fontSize: '1.1em' }}>
                            {orderDetails.status.toUpperCase()}
                        </Badge>
                    </div>

                    <h3 className="mt-5 mb-3">Course Details</h3>
                    {orderDetails.courses.map((course, index) => (
                        <Table key={index} bordered hover responsive className="mt-3">
                            <tbody>
                                <tr>
                                    <th>Course ID</th>
                                    <td>{course.id}</td>
                                </tr>
                                <tr>
                                    <th>Course Name</th>
                                    <td>{course.coursename}</td>
                                </tr>
                                <tr>
                                    <th>Duration</th>
                                    <td>{course.duration} months</td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td>₹ {course.price}</td>
                                </tr>
                                <tr>
                                    <th>Expiry Date</th>
                                    <td>{new Date(course.expiryDate).toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </Table>
                    ))}
                </CardBody>
            </Card>
        </div>

    );
};

export default Order;