import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { get, post } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { motion, AnimatePresence } from "framer-motion";
import Paginate from 'pages/Ui/Page'

const AssignTBA = (props) => {
    document.title = "Assign TBA";

    const [leads, setLeads] = useState([]);
    const [tbas, setTbas] = useState([]);
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [selectedTBA, setSelectedTBA] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalLeads, setTotalLeads] = useState(0);
    const [pageSize, setPageSize] = useState(100); // Number of items per page

    const breadcrumbItems = [
        { title: "Leads", link: "#" },
        { title: "Assign Lead", link: "#" }
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Assign Lead', breadcrumbItems);

        const fetchData = async () => {
            try {
                const { users } = await get(url.GET_TBAS);
                setTbas(users || []);

                const leadsResponse = await get(`${url.GET_LEAD_FOR_ASSIGN}?page=${currentPage}&pageSize=${pageSize}`);
                setLeads(leadsResponse.unassignedLeads || []);
                setTotalPages(leadsResponse.totalPages);
                setTotalLeads(leadsResponse.totalLeads);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [currentPage, pageSize]);

    const handleLeadSelect = (leadId) => {
        setSelectedLeads(prevSelected =>
            prevSelected.includes(leadId)
                ? prevSelected.filter(id => id !== leadId)
                : [...prevSelected, leadId]
        );
    };

    const handleSelectAll = () => {
        if (selectedLeads.length === leads.length) {
            setSelectedLeads([]);
        } else {
            setSelectedLeads(leads.map(lead => lead.id));
        }
    };

    const handleTBASelect = (e) => {
        setSelectedTBA(e.target.value);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        setCurrentPage(1); // Reset page to 1 when page size changes
    };

    const handleAssign = async () => {
        if (selectedTBA && selectedLeads.length > 0) {
            try {
                const response = await post(url.ASSIGN_TBA, { userId: selectedTBA, leadIds: selectedLeads });
                if (response.message === 'User assigned successfully') {
                    alert('TBA assigned successfully!');
                    const updatedLeadsResponse = await get(`${url.GET_LEAD_FOR_ASSIGN}?page=${currentPage}&pageSize=${pageSize}`);
                    setLeads(updatedLeadsResponse.unassignedLeads || []);
                    setTotalPages(updatedLeadsResponse.totalPages);
                    setTotalLeads(updatedLeadsResponse.totalLeads);
                    setSelectedLeads([]);
                    setSelectedTBA('');
                } else {
                    alert('Failed to assign TBA. Please try again.');
                }
            } catch (error) {
                console.error('Error assigning TBA:', error);
                alert('Failed to assign TBA. Please try again.');
            }
        } else {
            alert('Please select a TBA and at least one lead.');
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-center mt-5"
            >
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </motion.div>
        );
    }

    return (
        <React.Fragment>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4">Assign TBA</CardTitle>
                                <Form>
                                    <FormGroup>
                                        <Row>
                                            <Col md={6}>
                                                <Label for="tbaSelect">Select TBA</Label>
                                                <Input type="select" name="tbaSelect" id="tbaSelect" onChange={handleTBASelect} value={selectedTBA}>
                                                    <option value="">Choose TBA</option>
                                                    {tbas && tbas.map(({ id, username }) => (
                                                        <option key={id} value={id}>{username}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col md={6} className="d-flex align-items-end">
                                                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                                    <Button color="primary" onClick={handleAssign} disabled={!selectedTBA || selectedLeads.length === 0}>
                                                        Assign TBA
                                                    </Button>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <Label for="pageSizeSelect">Page Size</Label>
                                                <Input type="select" name="pageSizeSelect" id="pageSizeSelect" onChange={handlePageSizeChange} value={pageSize}>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                    <option value="500">500</option>
                                                </Input>
                                            </Col>
                                            <Col md={6} className="d-flex align-items-end">
                                                <div className="d-flex align-items-center mt-4">
                                                    <Input
                                                        type="checkbox"
                                                        id="selectAll"
                                                        onChange={handleSelectAll}
                                                        checked={selectedLeads.length === leads.length && leads.length > 0}
                                                        style={{ width: '25px', height: '25px' }}
                                                    />
                                                    <Label for="selectAll" className="mb-0 ml-2">Select All</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>Select</Th>
                                                    <Th>ID</Th>
                                                    <Th>Name</Th>
                                                    <Th>Phone Number</Th>
                                                    <Th>Email</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <AnimatePresence>
                                                    {Array.isArray(leads) && leads.length > 0 ? (
                                                        leads.map((item) => (
                                                            <motion.tr
                                                                key={item.id}
                                                                initial={{ opacity: 0 }}
                                                                animate={{ opacity: 1 }}
                                                                exit={{ opacity: 0 }}
                                                                transition={{ duration: 0.3 }}
                                                            >
                                                                <Td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedLeads.includes(item.id)}
                                                                        onChange={() => handleLeadSelect(item.id)}
                                                                    />
                                                                </Td>
                                                                <Td>{item.id}</Td>
                                                                <Td>{item.name}</Td>
                                                                <Td>{item.phoneNumber}</Td>
                                                                <Td>{item.email}</Td>
                                                            </motion.tr>
                                                        ))
                                                    ) : (
                                                        <motion.tr
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            exit={{ opacity: 0 }}
                                                        >
                                                            <Td colSpan="5">No leads available</Td>
                                                        </motion.tr>
                                                    )}
                                                </AnimatePresence>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                <Paginate
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(AssignTBA);
