import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// Import getCurrentUser function
import { getCurrentUser } from "../../helpers/url_helper"
import { get } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"

const SidebarContent = props => {
  const ref = useRef()
  const currentUser = getCurrentUser()
  const [counts, setCounts] = useState({
    requests: 0,
    todayOrders: 0,
    pendingOrders: 0,
    confirmedOrders: 0,
    todayUsers: 0,
    updatedLeads: 0,
    todayLeads: 0,
    todayCourses: 0,
  })

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await get(url.GET_ALL_COUNTS)
        const {
          requests,
          todayOrders,
          pendingOrders,
          confirmedOrders,
          todayUsers,
          updatedLeads,
          todayLeads,
          todayCourses,
        } = response.counts

        setCounts({
          requests,
          todayOrders,
          pendingOrders,
          confirmedOrders,
          todayUsers,
          updatedLeads,
          todayLeads,
          todayCourses,
        })
      } catch (error) {
        console.error("Error fetching counts:", error)
      }
    }

    fetchCounts()
    // Set up an interval to fetch counts every 4 minutes (240000 milliseconds)
    const intervalId = setInterval(fetchCounts, 240000)

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId)
  }, [])

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  // Function to handle menu item clicks
  const handleMenuClick = () => {
    // Get the sidebar element
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect" onClick={handleMenuClick}>
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {currentUser && currentUser.role !== "admin" && currentUser.role !== "superadmin" && (
              <li>
                <Link to="/list-lead" className="waves-effect" onClick={handleMenuClick}>
                <i className="mdi mdi-18px mdi-account"></i>
                <span>
                  {props.t("Leads")}{" "}
                  {counts.updatedLeads > 0 && (
                        <span className="badge bg-danger rounded-pill ms-1">
                          {counts.updatedLeads}
                        </span>
                      )}
                  </span>
                </Link>
              </li>
            )}
            {currentUser && currentUser.role === "admin"  && currentUser.role !== "superadmin" && (
              <>
                <li>
                  <Link to="/list-lead" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-box-multiple-outline"></i>
                    <span>
                      {props.t("Leads")}{" "}
                      {counts.todayLeads > 0 && (
                        <span className="badge bg-danger rounded-pill ms-1">
                          {counts.todayLeads}
                        </span>
                      )}
                    </span>
                  </Link>
                  <ul className="sub-menu">
                  <li>
                      <Link to="/list-lead" onClick={handleMenuClick}>{props.t("List leads")}</Link>
                    </li>
                    <li>
                      <Link to="/add-lead" onClick={handleMenuClick}>{props.t("Add leads")}</Link>
                    </li>
                    <li>
                      <Link to="/assign-tba" onClick={handleMenuClick}>{props.t("Assign Leads")}</Link>
                    </li>
                    <li>
                      <Link to="/transfer-tba" onClick={handleMenuClick}>
                        {props.t("Transfer Leads")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/request" className="waves-effect" onClick={handleMenuClick}>
                        <span>
                          {props.t("Request")}{" "}
                          {counts.requests > 0 && (
                            <span className="badge bg-danger rounded-pill ms-1">
                              {counts.requests}
                            </span>
                          )}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            <li>
            <Link to="#" className="has-arrow waves-effect">
            <i className="mdi mdi-email-outline"></i>                    <span>
                      {props.t("Orders")}{" "}
                      {counts.confirmedOrders > 0 && (
                  <span className="badge bg-danger rounded-pill ms-1">
                    {counts.confirmedOrders + counts.pendingOrders}
                  </span>
                )}
                    </span>
                  </Link>
             
              <ul className="sub-menu">
                <li>
                  <Link to="/add-order" onClick={handleMenuClick}>{props.t("Create Orders")}</Link>
                </li>
                <li>
                  <Link to="/pending-order" onClick={handleMenuClick}>
                    {props.t("Pending Orders")}{" "}
                    {counts.pendingOrders > 0 && (
                      <span className="badge bg-danger rounded-pill ms-1">
                        {counts.pendingOrders}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/confirmed-order" onClick={handleMenuClick}>
                    {props.t("Confirmed Orders")}{" "}
                    {counts.confirmedOrders > 0 && (
                      <span className="badge bg-danger rounded-pill ms-1">
                        {counts.confirmedOrders}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/renewals" className=" waves-effect" onClick={handleMenuClick}>
                <i className="mdi mdi-repeat"></i>
                <span>{props.t("Renewals")}</span>
              </Link>
            </li>
            {currentUser && currentUser.role !== "admin" && currentUser.role !== "superadmin" && (
              <>
                <li>
                  <Link to="/earning" className=" waves-effect" onClick={handleMenuClick}>
                    <i className="mdi mdi-contactless-payment"></i>
                    <span>{props.t("Earning")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/list-course" className=" waves-effect" onClick={handleMenuClick}>
                    <i className="mdi mdi-animation-play"></i>
                    <span>{props.t("Courses list")}</span>
                  </Link>
                </li>
              </>
            )}
            {currentUser && currentUser.role === "admin"  && currentUser.role !== "superadmin" && (
              <>
                <li>
                  <Link to="/commission" className=" waves-effect" onClick={handleMenuClick}>
                    <i className="mdi mdi-account-cash-outline"></i>
                    <span>{props.t("Commission")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-tie"></i>
                    <span>{props.t("TBA")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/add-tba" onClick={handleMenuClick}>{props.t("Add TBA")}</Link>
                    </li>
                    <li>
                      <Link to="/list-tba" onClick={handleMenuClick}>{props.t("TBAs")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-animation-play"></i>
                    <span>{props.t("Course")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/courses" onClick={handleMenuClick}>{props.t("Add Course")}</Link>
                    </li>
                    <li>
                      <Link to="/list-course" onClick={handleMenuClick}>
                        {props.t("Course list")}{" "}
                        {counts.todayCourses > 0 && (
                          <span className="badge bg-danger rounded-pill ms-1">
                            {counts.todayCourses}
                          </span>
                        )}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
